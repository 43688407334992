export const API_URL = 'https://api.quantumaxis.com.br/widgets/v1/'

export const API_SUBSCRIPTION_KEY = 'fdff8418fa7046999f573681a6bc06e7'

export const HOME_TITLE = 'Super Carteira,'

export const THEME = 'super-carteira'

export const SHARE_URL = 'https://supercarteira-beta.quantumfinance.com.br/asset#';
export const APPLICATION_INSIGHTS_CONNECTION_STRING = 'InstrumentationKey=24413b36-11c2-436f-8a26-0505ec7c92a4;IngestionEndpoint=https://brazilsouth-0.in.applicationinsights.azure.com/'

export const REDUX_PANE = false;
export const REDUX_LOGGER = false;
